const NavbarItems = [
  {
    name: "Home",
    route: "/home",
  },
  {
    name: "Policies",
    route: "/policies",
  },
  {
    name: "About",
    route: "/about",
  },
];

export default NavbarItems;
